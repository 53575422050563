/**
 * ContactCard.jsx
 *
 * @file This component creates a card to display contact information.
 * @author Robin Walter <hello@robinwalter.me>
 */

import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Hidden from '@mui/material/Hidden'
import IconButton from '@mui/material/IconButton'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

// internal imports
import { isOdd } from '../../utils'
import { MailtoDialog } from '../alerts'

/**
 * Create a card to display the contact information.
 *
 * @param {Object} props The component props.
 * @returns {Node} The rendered card.
 */
const ContactCard = ( { children, index, mail, name, profile, title, titleMailMe } ) => {

	/** Define a state to open/close the mailto dialog. */
	const [ isMailtoOpen, setMailtoOpen ] = useState( false )

	if ( !isOdd( index ) )
		return (
            <Card raised sx={{ display: 'flex', width: '100%' }}>
				<Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
					<CardContent sx={{ flex: '1 0 auto', pb: 0 }}>
						<Typography color="primary" variant="body1">{ title }</Typography>
						<Typography color="secondary" variant="h5">{ name }</Typography>
						{ children }
					</CardContent>
					<CardActions sx={{ alignItems: 'center', display: 'flex', pl: 1, pt: 0 }}>
						<Box>
							<Tooltip
								placement="bottom"
								title={ titleMailMe }>
								<IconButton
                                    aria-label={ titleMailMe }
                                    color="primary"
                                    onClick={ () => { setMailtoOpen( !isMailtoOpen ) } }
                                    tabIndex="-1"
                                    size="large">
									<AlternateEmailIcon />
								</IconButton>
							</Tooltip>
						</Box>
					</CardActions>
				</Box>
				{ profile &&
				<Hidden smDown>
					<Tooltip
						placement="bottom"
						title={ name }>
						<GatsbyImage
							alt={ name }
							as={ Box }
							image={ getImage( profile ) }
							objectFit="contain" />
					</Tooltip>
				</Hidden>
				}
				<MailtoDialog
					onClose={ () => { setMailtoOpen( !isMailtoOpen ) } }
					open={ isMailtoOpen }
					to={ mail } />
			</Card>
        )

	return (
        <Card raised sx={{ display: 'flex', width: '100%' }}>
			{ profile &&
				<Hidden smDown>
					<Tooltip
						placement="bottom"
						title={ name }>
						<GatsbyImage
							alt={ name }
							as={ Box }
							image={ getImage( profile ) }
							objectFit="cover" />
					</Tooltip>
				</Hidden>
				}
			<Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
				<CardContent sx={{ flex: '1 0 auto', pb: 0 }}>
					<Typography color="primary" variant="body1">{ title }</Typography>
					<Typography color="secondary" variant="h5">{ name }</Typography>
					{ children }
				</CardContent>
				<CardActions  sx={{ alignItems: 'center', display: 'flex', pl: 1, pt: 0 }}>
					<Box>
						<Tooltip
							placement="bottom"
							title={ titleMailMe }>
							<IconButton
                                aria-label={ titleMailMe }
                                color="primary"
                                onClick={ () => { setMailtoOpen( !isMailtoOpen ) } }
                                tabIndex="-1"
                                size="large">
								<AlternateEmailIcon />
							</IconButton>
						</Tooltip>
					</Box>
				</CardActions>
			</Box>
			<MailtoDialog
				onClose={ () => { setMailtoOpen( !isMailtoOpen ) } }
				open={ isMailtoOpen }
				to={ mail } />
		</Card>
    )
}

ContactCard.defaultProps = {
	titleMailMe: 'Nachricht'
}

/**
 * Define the (expected) prop-types of this component.
 *
 * @typedef {Object} ContactCard.propTypes
 * @property {Node}   children     – The children that shall be rendered inside of this component.
 * @property {number} index        – The index of this card.
 * @property {string} mail         – An e-mail address.
 * @property {string} name         – A name.
 * @property {string} [profileSrc] – A profile picture (if available).
 * @property {string} title        – A title to display.
 * @property {string} titleMailMe  – A title to display for the dialog.
 */
ContactCard.propTypes = {
	children: PropTypes.node.isRequired,
	index: PropTypes.number.isRequired,
	mail: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	profileSrc: PropTypes.string,
	title: PropTypes.string.isRequired,
	titleMailMe: PropTypes.string.isRequired
}

export default ContactCard
