/**
 * AnsprechpartnerRegions.jsx
 *
 * @file This component creates the tabs with the regions.
 * @author Robin Walter <hello@robinwalter.me>
 */

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import logging from 'loglevel'
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'

// internal imports
import { ContactCard } from '../ContactCard'

/**
 * This component renders the regions for the Ansprechpartner page.
 *
 * @returns {Node} The rendered regions.
 */
const AnsprechpartnerRegions = ({ people }) => {
	const logger = logging

	/** Define a state to store all reference people */
	const [regions, setRegions] = useState([])
	/** Define a state to store the current value. */
	const [ value, setValue ] = useState( 0 )

	useMemo(
		() => {
			// Store the regions in a placeholder before pushing to the state
			let newRegions = []
			// First we try to extract all regional networks
			let networks = []
			people.forEach((person) => {
				let network = person.regionalNetwork
				// Strip whitespaces and numbers from the network
				network = network.split(" ")[0]
				// Add the network to the list, if it not already exists
				if (!networks.includes(network)) {
					networks.push(network)
				}
			})
			logger.debug("networks: ", networks)

			// Iterate over the networks to find all contacts
			networks.forEach((network) => {
				let contacts = []
				people.forEach((person) => {
					// Check if person is part of this network
					if (person.regionalNetwork.includes(network)) {
						contacts.push(person)
					}
				})
				// Sort contacts by their network
				contacts.sort((c1, c2) => (c1.regionalNetwork > c2.regionalNetwork) ? 1 : ((c2.regionalNetwork > c1.regionalNetwork) ? -1 : 0))
				logger.debug(`Network (${network}) has contacts: `, contacts)
				// Push network with contacts to regions
				newRegions.push({contacts: contacts, regionalNetwork: network})
			})
			logger.debug("regions: ", newRegions)

			// Store regions in state
			setRegions(newRegions)
		},
		[people]
	)

	return regions.length > 0 && (
		<Box sx={{ flexGrow: 1, width: '100%' }}>
			<Tabs
				aria-label="Regionen"
				indicatorColor="primary"
				onChange={ ( _, value ) => { setValue( value ) } }
				scrollButtons="auto"
				textColor="primary"
				value={ value }
				variant="scrollable">
				{ regions.map( ( { regionalNetwork }, i ) =>
					<Tab key={ i } label={ `Region ${ regionalNetwork }` } />
				) }
			</Tabs>
			<Grid container role="tabpanel" spacing={ 2 } sx={{ py: 3 }}>
				{ regions[ value ].contacts.map( ( contact, i ) => (
					<Grid item key={ contact.id } xs={ 12 }>
						<ContactCard
							index={ i }
							mail={ contact.user.email }
							name={ contact.user.name }
							profile={ contact.localFile }
							title={ `Regionalkoordinator/in ${ contact.regionalNetwork }` }>
							<Typography variant="body2">{ contact.cities }</Typography>
						</ContactCard>
					</Grid>
				) ) }
			</Grid>
		</Box>
	)
}

/**
 * Define the (expected) prop-types of this component.
 *
 * @typedef {Object} AnsprechpartnerRegions.propTypes
 * @property {Object} people – The people who are reference people
 */
 AnsprechpartnerRegions.propTypes = {
	people: PropTypes.arrayOf(
		PropTypes.shape({
			cities: PropTypes.string.isRequired,
			id: PropTypes.string.isRequired,
			regionalNetwork: PropTypes.string.isRequired,
			user: PropTypes.shape({
				email: PropTypes.string.isRequired,
				id: PropTypes.string.isRequired,
				name: PropTypes.string.isRequired,
			}).isRequired,
			localFile: PropTypes.object
		}).isRequired
	).isRequired
}

export default AnsprechpartnerRegions
