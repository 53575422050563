/**
 * ansprechpartner.jsx
 *
 * @file Ansprechpartner page of the web application.
 * @author Robin Walter <hello@robinwalter.me>
 */

import Box from '@mui/material/Box'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { graphql } from 'gatsby'
import Grid from '@mui/material/Grid'
import React from 'react'
import Typography from '@mui/material/Typography'

// internal imports
import { AnsprechpartnerRegions } from '../components/AnsprechpartnerRegions'
import { ContactCard } from '../components/ContactCard'

/**
 * This component represents the Ansprechpartner page of this application.
 *
 * @param {Object} props The component props.
 * @returns {Node} The rendered page.
 */
const AnsprechpartnerPage = ({ data }) => {

	return (
		<>
			<GatsbySeo title="Ansprechpartner" />
			<Typography align="center" color="primary" gutterBottom variant="h4">Ansprechpartner</Typography>
			<Typography variant="body2">
				Hier finden Sie die richtige Ansprechpartnerin oder den richtigen Ansprechpartner für Ihr Anliegen. Sie können uns helfen, indem Sie uns gleich gezielt ansprechen. Sollten Sie Ihr Anliegen hier nicht wiederfinden, stellen Sie bitte eine allgemeine Anfrage.
			</Typography>
			<Box sx={{ my: 5 }}>
				<Grid container spacing={ 2 }>
					{ data.allCoordinator.nodes.map(( coordinator, i ) =>
						<Grid item key={ `${ coordinator.email }-${ i }` } xs={ 12 }>
							<ContactCard
								index={ i }
								key={ coordinator.email }
								mail={ coordinator.email }
								name={ coordinator.name }
								profile={ coordinator.localFile }
								title={ coordinator.title }>
								{ coordinator.text.map(( text, j ) => <Typography key={ j } variant="body2">{ text }</Typography> ) }
							</ContactCard>
						</Grid>
					)}
				</Grid>
			</Box>
			<Typography color="primary" gutterBottom variant="h6">Regionalkoordinatorinnen und Regionalkoordinatoren</Typography>
			<Typography variant="body2">› Betreuung von (bestehenden) Projektschulen</Typography>
			<Typography variant="body2">› Organisation der Regionalwettbewerbe</Typography>
			<Typography paragraph variant="body2">› Kontakte zu lokalen Medien</Typography>
			<Typography variant="body2">
				Unten finden Sie eine Übersicht der regionalen Zuordnungen nach Kreisen und kreisfreien Städten.
			</Typography>
			<Box sx={{ my: 5 }}><AnsprechpartnerRegions people={ data.allReferencePerson.nodes } /></Box>
		</>
	)

}

export default AnsprechpartnerPage

export const query = graphql`{
	allCoordinator {
		nodes {
			email
			id
			localFile {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						layout: CONSTRAINED
						width: 175
					)
				}
			}
			name
			text
			title
		}
  	}
	allReferencePerson {
		nodes {
			cities
			id
			regionalNetwork
			user {
				email
				id
				name
			}
			localFile {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						layout: CONSTRAINED
						width: 175
					)
				}
			}
		}
  	}
}
`
